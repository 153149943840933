<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <AddHeader @submit="submit()" @cancel="addCancel" v-if="operation === 'add'">
          <span slot="otherButton" class="vg_mr_8 flexHV">
            报价日期:
            <el-date-picker v-model="quotationDate" :disabled="isShow" clearable placeholder="请选择" size="small" style="width: 150px" type="date" />
          </span>
        </AddHeader>
        <EditHeader
          v-if="operation === 'edit'"
          :isShow="isShow"
          :btn="btn"
          :strForm="{ status: formStatus }"
          @openEdit="isShow = false"
          @closeEdit="closeEdit"
          @submit="submit"
          @refresh="getTableDataById"
        >
          <span slot="otherButton" class="vg_mr_8 flexHV">
            报价日期:
            <el-date-picker v-model="quotationDate" :disabled="isShow" clearable placeholder="请选择" size="small" style="width: 150px" type="date" />
          </span>
        </EditHeader>
        <DynamicForm
          :total-width="addForm.totalWidth"
          :split="addForm.split"
          :form-list="addForm.formProperties"
          ref="dynamicForm"
          :computedData="{
            quot_salary: quot_salary,
            discount_price: discount_price,
            quot_swap: quot_swap,
            profit_margin: profit_margin,
            mtrl_price: mtrl_price
          }"
          :is-show="isShow"
          class="dynamicForm"
        />
        <div class="vg_mb_8">
          <el-button :disabled="isShow" type="primary" size="small" @click="addSubTableRow()" icon="el-icon-plus">新增</el-button>
          <el-button :disabled="isShow" type="danger" size="small" @click="delSubTableRow()" icon="el-icon-delete">删除</el-button>
        </div>
        <SearchTable
          ref="multiTable"
          :data="subTableData"
          :tableRowKey="'usqu_mtrl_id'"
          :columns="subTableProperties"
          v-loading="loadFlag"
          :totalPage="subTotalPage"
          :needPagination="false"
          :needSearch="false"
          :computed-data="{ mtrl_money: mtrl_money, mtrl_dosage: mtrl_dosage, usb_money: usb_money }"
          @select="selectRows"
          @selection-change="handleSelectionChange"
          @select-all="selectRows"
          @getTableData="() => {}"
          :is-show="isShow"
        >
        </SearchTable>
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" lazy v-if="operation === 'edit'">
        <ApprovalFlow :isShow="isShow" @draftState="init" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'" v-if="operation === 'edit'">
        <RelatedDocuments ref="relatedDocuments" />
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="导入样品核价单" :visible.sync="pricingListDialog" width="70%">
      <search-table
        ref="pricingTable"
        :data="pricingTableData"
        :tableRowKey="'pricingTableRowKey'"
        :columns="pricingTableProperties"
        v-loading="pricingLoadFlag"
        :totalPage="pricingTotalPage"
        :page-size="10"
        :need-fixed-height="true"
        @selection-change="val => (pricingSelection = val)"
        @getTableData="getPricingData"
        @row-dblclick="confirmImport"
      />
    </el-dialog>
  </div>
</template>

<script>
import SearchTable from '@/components/table/SearchTableV2.vue';
import DynamicForm from '@/components/DynamicFormV2.vue';
import AddHeader from '@/views/component/addHeader.vue';
import { pricingTableProperties, subTableProperties, usquFormProperties } from './usqu.js';
import { BigNumber } from 'bignumber.js';
import { dyjAPI } from '@api/modules/dyj';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { getNoCatch } from '@api/request';
import { custAPI } from '@api/modules/cust';
import { optnAPI } from '@api/modules/optn';
import EditHeader from '@/views/component/editHeaderLine.vue';
import ApprovalFlow from '@/views/component/apprCommon/apprMain.vue';
import RelatedDocuments from '@/views/component/docuCommon/docuAbout.vue';
import { usquAPI } from '@api/modules/usqu';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'UsquList',
  components: { RelatedDocuments, ApprovalFlow, EditHeader, AddHeader, DynamicForm, SearchTable },
  data() {
    return {
      pricingSelection: [],
      pricingTableProperties: cloneDeep(pricingTableProperties),
      pricingTableData: [],
      pricingTotalPage: 0,
      pricingLoadFlag: false,
      pricingListDialog: false,
      subTableData: [],
      subTableMap: new Map(),
      subTotalPage: 0,
      subTableProperties: cloneDeep(subTableProperties),
      addForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(usquFormProperties)
      },
      loadFlag: false,
      multiSelection: [],
      btn: {},
      rateList: [],
      isShow: true,
      activeName: 'first',
      operation: '',
      quotationDate: new Date(),
      formStatus: 0
    };
  },
  created() {
    let path = this.$route.path;
    this.operation = path.substring(path.indexOf('_') + 1);
  },
  mounted() {
    this.isShow = this.operation === 'edit';
    this.addForm.formProperties[1].appendClick = () => this.importSample();
    this.init();
  },
  computed: {
    quot_salary() {
      let calculated = new BigNumber(1);
      let usb_quote = new BigNumber(this.addForm.formProperties[15].model);
      let qc_or_qa = new BigNumber(this.addForm.formProperties[13].model);
      let profit_margin = new BigNumber(this.addForm.formProperties[12].model);
      let mtrl_price = new BigNumber(this.addForm.formProperties[7].model);
      let rmb_freight = new BigNumber(this.addForm.formProperties[20].model);
      if (!usb_quote.isFinite() || !qc_or_qa.isFinite() || !profit_margin.isFinite() || !mtrl_price.isFinite() || !rmb_freight.isFinite()) {
        this.addForm.formProperties[21].model = '';
        return '';
      }
      let calculatedResult = usb_quote.minus(qc_or_qa).div(calculated.plus(profit_margin)).minus(mtrl_price.plus(rmb_freight)).toFixed(4);
      this.addForm.formProperties[21].model = calculatedResult;
      return calculatedResult;
    },
    discount_price() {
      let usb_quote = new BigNumber(this.addForm.formProperties[15].model);
      let discount_rate = new BigNumber(this.addForm.formProperties[16].model);
      if (!usb_quote.isFinite() || !discount_rate.isFinite()) {
        this.addForm.formProperties[17].model = '';
        return '';
      }
      let calculatedResult = usb_quote.times(discount_rate).toFixed(4);
      this.addForm.formProperties[17].model = calculatedResult;
      return calculatedResult;
    },
    quot_swap() {
      let quta_price = new BigNumber(this.addForm.formProperties[9].model);
      let bb_call = new BigNumber(this.addForm.formProperties[6].model);
      let back_or_drop = new BigNumber(this.addForm.formProperties[10].model);
      let tempNumber = new BigNumber(1.13);
      let rmb_freight = new BigNumber(this.addForm.formProperties[20].model);
      let discount_price = new BigNumber(this.addForm.formProperties[17].model);
      let tempNumber2 = new BigNumber(1);
      let quot_brokerage = new BigNumber(this.addForm.formProperties[19].model);
      if (
        !quta_price.isFinite() ||
        !back_or_drop.isFinite() ||
        !bb_call.isFinite() ||
        !discount_price.isFinite() ||
        !rmb_freight.isFinite() ||
        !quot_brokerage.isFinite()
      ) {
        this.addForm.formProperties[18].model = '';
        return '';
      }
      let calculatedResult = new BigNumber(new BigNumber(new BigNumber(quta_price.plus(back_or_drop).plus(bb_call)).div(tempNumber)).plus(rmb_freight))
        .div(discount_price)
        .times(new BigNumber(tempNumber2.plus(quot_brokerage)))
        .toFixed(4);
      this.addForm.formProperties[18].model = calculatedResult;
      return calculatedResult;
    },
    mtrl_money() {
      return index => {
        let smpl_mtrl_consume = new BigNumber(this.subTableData[index].smpl_mtrl_consume);
        let mtrl_price = new BigNumber(this.subTableData[index].mtrl_price);
        if (!smpl_mtrl_consume.isFinite() || !mtrl_price.isFinite()) {
          this.subTableData[index].mtrl_money = '';
          return '';
        }
        let calculatedResult = smpl_mtrl_consume.times(mtrl_price).toFixed(4);
        this.subTableData[index].mtrl_money = calculatedResult;
        return calculatedResult;
      };
    },
    mtrl_dosage() {
      return index => {
        let smpl_mtrl_consume = new BigNumber(this.subTableData[index].smpl_mtrl_consume);
        let mtrl_coef = new BigNumber(this.subTableData[index].mtrl_coef);
        if (!smpl_mtrl_consume.isFinite() || !mtrl_coef.isFinite()) {
          this.subTableData[index].mtrl_dosage = '';
          return '';
        }
        let calculatedResult = smpl_mtrl_consume.times(mtrl_coef).toFixed(4);
        this.subTableData[index].mtrl_dosage = calculatedResult;
        return calculatedResult;
      };
    },
    usb_money() {
      return index => {
        let mtrl_dosage = new BigNumber(this.subTableData[index].mtrl_dosage);
        let usb_price = new BigNumber(this.subTableData[index].usb_price);
        if (!mtrl_dosage.isFinite() || !usb_price.isFinite()) {
          this.subTableData[index].usb_money = '';
          return '';
        }
        let calculatedResult = mtrl_dosage.times(usb_price).toFixed(4);
        this.subTableData[index].usb_money = calculatedResult;
        return calculatedResult;
      };
    },
    profit_margin() {
      let estim_num = new BigNumber(this.addForm.formProperties[4].model);
      let temp = '';
      this.rateList.forEach(({ min, max, rate }) => {
        if (estim_num.toNumber() >= Number(min) && estim_num.toNumber() <= Number(max)) {
          temp = rate;
        }
      });
      this.addForm.formProperties[12].model = temp;
      return temp;
    },
    mtrl_price() {
      let temp = [];
      for (let i = 0; i < this.subTableData.length; i++) {
        let smpl_mtrl_consume = new BigNumber(this.subTableData[i].smpl_mtrl_consume);
        let mtrl_coef = new BigNumber(this.subTableData[i].mtrl_coef);
        let usb_price = new BigNumber(this.subTableData[i].usb_price);
        if (!smpl_mtrl_consume.isFinite() || !mtrl_coef.isFinite() || !usb_price.isFinite()) {
          this.addForm.formProperties[7].model = '自动计算';
          return '';
        }
        temp.push(smpl_mtrl_consume.times(mtrl_coef).times(usb_price).toFixed());
      }
      let mtrlPriceCount = BigNumber.sum(...temp);
      if (!mtrlPriceCount.isFinite()) return '自动计算';
      this.addForm.formProperties[7].model = mtrlPriceCount.toFixed(4);
      return mtrlPriceCount.toFixed(4);
    }
  },
  methods: {
    async init() {
      await this.reset();
      await this.getCustomerList();
      await this.getOptionsById();
      await this.getTableDataById();
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs.dynamicForm.$refs.form.validate(valid => {
          if (valid) {
            let formModel = this.$refs.dynamicForm.getFormModel();
            formModel.quotationList = this.subTableData.concat(Array.from(this.subTableMap.values()));
            formModel.qout_time = this.quotationDate?.getTime();
            if (this.operation === 'add') {
              usquAPI.add_usqus(formModel).then(({ data }) => {
                this.$message.success('保存成功!');
                this.jump('/usqu_edit', {
                  key: UrlEncode.encode(
                    JSON.stringify({
                      perm_id: this.$route.query.perm_id,
                      form_id: data.form_id
                    })
                  )
                });
                this.reset();
                this.quotationDate = null;
                this.subTableData = [];
              });
            }
            if (this.operation === 'edit') {
              formModel.usqu_id = JSON.parse(UrlEncode.decode(this.$route.query.key)).form_id;
              usquAPI.edit_usqus(formModel).then(({ data }) => {
                this.$message.success('保存成功!');
                this.isShow = true;
                this.init();
              });
            }
          } else {
            return this.$message.warning('请把必填内容补充完整');
          }
        });
      });
    },
    addCancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.isShow = false;
        this.reset();
        this.quotationDate = null;
        this.subTableData = [];
        const permId = this.$route.query.perm_id;
        this.jump('/usqu_list?perm_id=' + permId);
      });
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.init();
        this.$message.info('已撤销编辑!');
      });
    },
    getTableDataById() {
      if (this.operation !== 'edit') return;
      let id = JSON.parse(UrlEncode.decode(this.$route.query.key)).form_id;
      usquAPI.get_usqus_by_id({ usqu_id: id }).then(({ data }) => {
        this.btn = data.btn;
        this.subTableData = data.form.quotationList;
        this.formStatus = data.form.status;
        this.quotationDate = new Date(data.form.create_time);
        this.addForm.formProperties.forEach(item => {
          if (item.prop !== 'imag') this.$set(item, 'model', data.form[item['prop']]);
        });
      });
    },
    async importSample() {
      await (this.pricingListDialog = true);
      await this.getPricingData();
    },
    async getPricingData() {
      await (this.pricingLoadFlag = true);
      await dyjAPI.getRqtaList(this.$refs.pricingTable.searchForm).then(({ data }) => {
        this.pricingTableData = data.list;
        this.pricingTotalPage = data.total;
      });
      await (this.pricingLoadFlag = false);
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    delSubTableRow() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let { tableData, map } = deleteTableRow('usqu_mtrl_id', this.subTableData, this.subTableMap, this.multiSelection);
      this.subTableData = tableData;
      this.subTableMap = map;
    },
    confirmImport(row) {
      let { imge_url, quta_no, requ_no, smpl_no, quta_price, smpl_name, childList } = row;
      if (imge_url.indexOf('aliyun') !== -1) {
        this.addForm.formProperties[0].model = imge_url;
      } else {
        this.addForm.formProperties[0].model = imge_url + '_m.jpg';
      }
      this.addForm.formProperties[1].model = quta_no;
      this.addForm.formProperties[2].model = requ_no;
      this.addForm.formProperties[3].model = smpl_no;
      this.addForm.formProperties[9].model = quta_price;
      this.addForm.formProperties[5].model = smpl_name;
      childList.forEach(item => (item.otherDisabled = true));
      this.subTableData = this.subTableData.concat(childList);
      this.pricingListDialog = false;
    },
    getCustomerList() {
      getNoCatch(custAPI.getAllCustsV1, { status: 2 }).then(({ data }) => {
        this.addForm.formProperties[8].options = Array.from(data.list, ({ cust_id, cust_name }) => {
          return { value: cust_id, label: cust_name };
        });
      });
    },
    getOptionsById() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10025 }).then(({ data }) => {
        this.rateList = Array.from(data.form.optn_cntt_list, ({ param2, param1, param3 }) => {
          return { min: param2, max: param1, rate: param3 };
        });
      });
    },
    reset() {
      this.$refs.dynamicForm.reset();
    },
    addSubTableRow() {
      this.subTableData.push({});
    }
  }
};
</script>

<style scoped></style>
