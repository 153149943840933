import store from '@/store';
import { keep4Decimal, keepNumber } from '@assets/js/regExUtil';
import { getDateNoTime } from '@assets/js/dateUtils';

export const usquFormProperties = [
  {
    label: '图片:',
    prop: 'imag_url',
    itemType: 'image',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    itemHeight: 300,
    itemWidth: 300,
    imageCustom: true,
    index: 0
  },
  {
    label: '样品核价单号:',
    prop: 'rqta_no',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    inputAppend: '导入',
    index: 1
  },
  {
    label: '打样单号:',
    prop: 'requ_no',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    index: 2
  },
  {
    label: '我司货号:',
    prop: 'smpl_no',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    index: 3
  },
  {
    label: '预计数量:',
    prop: 'estim_num',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 50,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    input: (list, index, val) => (list[index].model = keepNumber(val)),
    index: 4
  },
  {
    label: '中文品名:',
    prop: 'quot_name',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 20,
    rules: {
      required: true,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    index: 5
  },
  {
    label: 'BB叫:',
    prop: 'bb_call',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    maxlength: 10,
    subWidth: 80,
    show: true,
    disabled: false,
    inputAppend: '¥',
    index: 6,
    input: (list, index, val) => (list[index].model = keep4Decimal(val))
  },
  {
    label: '物料成本:',
    prop: 'mtrl_price',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    index: 7,
    placeholder: '自动计算'
  },
  {
    label: '客户简称:',
    prop: 'cust_id',
    itemType: 'select',
    model: '',
    options: [],
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    index: 8
  },
  {
    label: '采购核价:',
    prop: 'quta_price',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 10,
    rules: {
      required: true,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    index: 9
  },
  {
    label: '背卡/吊牌:',
    prop: 'back_or_drop',
    model: '',
    itemType: 'input',
    size: 'small',
    maxlength: 10,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    inputAppend: '¥',
    index: 10,
    input: (list, index, val) => (list[index].model = keep4Decimal(val))
  },
  {
    label: '客户货号:',
    prop: 'client_no',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 20,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    index: 11
  },
  {
    label: '利润率:',
    prop: 'profit_margin',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    placeholder: '根据预计数量自动计算',
    inputAppend: '%',
    index: 12
  },
  {
    label: 'QC/QA:',
    prop: 'qc_or_qa',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 10,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    inputAppend: '$',
    input: (list, index, val) => (list[index].model = keep4Decimal(val)),
    index: 13
  },
  {
    label: '运费:',
    prop: 'usb_freight',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 10,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    inputAppend: '$',
    input: (list, index, val) => (list[index].model = keep4Decimal(val)),
    index: 14
  },
  {
    label: '美金报价:',
    prop: 'usb_quote',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 10,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    inputAppend: '$',
    input: (list, index, val) => (list[index].model = keep4Decimal(val)),
    index: 15
  },
  {
    label: '折扣率:',
    prop: 'discount_rate',
    itemType: 'input',
    size: 'small',
    model: 0.89,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    inputAppend: '%',
    input: (list, index, val) => (list[index].model = keep4Decimal(val)),
    index: 16
  },
  {
    label: '折扣价:',
    prop: 'discount_price',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    placeholder: '自动计算',
    index: 17
  },
  {
    label: '换汇:',
    prop: 'quot_swap',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 10,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    placeholder: '自动计算',
    index: 18
  },
  {
    label: '佣金:',
    prop: 'quot_brokerage',
    itemType: 'input',
    model: '',
    size: 'small',
    maxlength: 5,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    input: (list, index, val) => (list[index].model = keepNumber(val)),
    index: 19
  },
  {
    label: '运费:',
    prop: 'rmb_freight',
    model: '',
    itemType: 'input',
    size: 'small',
    maxlength: 10,
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    inputAppend: '¥',
    input: (list, index, val) => (list[index].model = keep4Decimal(val)),
    index: 20
  },
  {
    label: '工资:',
    prop: 'quot_salary',
    itemType: 'input',
    model: '',
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: true,
    inputAppend: '$',
    placeholder: '自动计算',
    index: 21
  },
  {
    label: '是否计入面料统计:',
    prop: 'quot_flag',
    itemType: 'radio',
    model: '',
    radioOptions: [
      { label: 2, text: '是' },
      { label: 1, text: '否' }
    ],
    size: 'small',
    rules: {
      required: false,
      message: ' '
    },
    subWidth: 80,
    show: true,
    disabled: false,
    index: 22
  }
];
export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '报价单号', prop: 'usqu_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '报价日期',
    prop: 'qout_time',
    itemType: 'date',
    input: true,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, false)
  },
  { label: '样品核价单号', prop: 'rqta_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '我司货号', prop: 'smpl_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '中文品名', prop: 'quot_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '采购部核价',
    prop: 'quta_price',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: true
  },
  {
    label: '美金报价',
    prop: 'usb_quote',
    itemType: 'input',
    input: true,
    sortable: true,
    widthAuto: true,
    searchInput: (val, searchForm, prop) => (searchForm[prop] = keepNumber(val))
  },
  { label: '录入人', prop: 'stff_name', itemType: 'input', sortable: true, widthAuto: true },
  { label: '所属部门', prop: 'dept_name', itemType: 'input', input: false, sortable: true, widthAuto: true },
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, false)
  },
  store.state.status
];
export const subTableProperties = [
  store.state.selection,
  store.state.index,
  {
    label: '物料编号',
    prop: 'mtrl_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', disabled: row => row.otherDisabled }
  },
  {
    label: '物料名称',
    prop: 'mtrl_name',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', disabled: row => row.otherDisabled }
  },
  {
    label: '物料英文名称',
    prop: 'mtrl_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', disabled: row => row.otherDisabled }
  },
  {
    label: '潘通色号',
    prop: 'mtrl_color',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', disabled: row => row.otherDisabled }
  },
  /*  {
    label: '数量单位',
    prop: 'mtrl_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input' }
  },*/
  {
    label: '用量',
    prop: 'smpl_mtrl_consume',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input' }
  },
  {
    label: '单价',
    prop: 'mtrl_price',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', maxLength: 10, input: (val, row) => (row.mtrl_price = keep4Decimal(val)) }
  },
  {
    label: '金额',
    prop: 'mtrl_money',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', disabled: true, placeholder: '自动计算' }
  },
  {
    label: '系数',
    prop: 'mtrl_coef',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', input: (val, row) => (row.mtrl_coef = keep4Decimal(val)) }
  },
  {
    label: '客户用量',
    prop: 'mtrl_dosage',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', disabled: true, placeholder: '自动计算' }
  },
  {
    label: '美金单价',
    prop: 'usb_price',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', input: (val, row) => (row.usb_price = keep4Decimal(val)) }
  },
  {
    label: '美金金额',
    prop: 'usb_money',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', disabled: true, placeholder: '自动计算' }
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: { required: false, type: 'input', inputType: 'textarea', autosize: true }
  }
];
export const pricingTableProperties = [
  store.state.index,
  { label: '我司货号 (工厂打样单号)', prop: 'smpl_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '中文品名 (样品名称)', prop: 'smpl_name', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '采购核价人', prop: 'quta_pricer_name', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '采购核价￥(含税价格)', prop: 'quta_price', itemType: 'input', input: false, sortable: false, widthAuto: false }
];
